"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var antd_1 = require("antd");
var TableProps_enum_1 = require("../Enums/TableProps.enum");
var dynamic_import_helpers_1 = require("../../helpers/dynamic_import.helpers");
var lodash_1 = require("lodash");
var q_1 = require("q");
var react_i18next_1 = require("react-i18next");
require("./RzgSelect.less");
var Option = antd_1.Select.Option;
var RzgSelect = /** @class */ (function (_super) {
    __extends(RzgSelect, _super);
    function RzgSelect(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.list = function (refFilter) {
            var _a = _this.props, fetchModel = _a.fetchModel, graphqlQueryName = _a.graphqlQueryName, idModel = _a.idModel;
            refFilter && _this.setState({ foundModel: null });
            var filters = _this.state.filters;
            _this.setState({ loading: true });
            return (0, q_1.Promise)(function (resolve, reject) {
                (0, dynamic_import_helpers_1.dynamicImportOnboarding)(fetchModel, function (request) {
                    var _a;
                    if (fetchModel === 'country' && graphqlQueryName === 'citiesList' && refFilter && !refFilter[1]) {
                        return;
                    }
                    ;
                    (_a = request.default)[graphqlQueryName || 'list'].apply(_a, (refFilter || filters)).then(function (_a) {
                        var data = _a.data;
                        if (data) {
                            var items = data[Object.keys(data)[0]].items;
                            var list = _this.getNestedItems(items || data[Object.keys(data)[0]]);
                            var dataList = list.map(function (item) {
                                var _id = item[idModel] || item.id || item._id;
                                return {
                                    _id: _id,
                                    name: _this.getName(item),
                                    key: _id,
                                    data: item,
                                };
                            });
                            var foundModel_1 = _this.state.foundModel;
                            if (foundModel_1 && !dataList.find(function (item) { return item._id === foundModel_1._id; })) {
                                _this.setState({ dataList: __spreadArray(__spreadArray([], dataList, true), [foundModel_1], false) });
                                _this.setState({ foundModel: null });
                            }
                            else {
                                _this.setState({ dataList: dataList });
                            }
                            resolve(dataList);
                        }
                        _this.setState({ loading: false });
                    })
                        .catch(function (e) {
                        reject(e);
                        _this.setState({ loading: false });
                    });
                });
            });
        };
        _this.findModel = function (refFilter, graphqlQuery) {
            var _a = _this.props, fetchModel = _a.fetchModel, value = _a.value, findModelGraphgql = _a.findModelGraphgql, idModel = _a.idModel;
            var findFilters = _this.state.findFilters;
            return (0, q_1.Promise)(function (resolve, reject) {
                (0, dynamic_import_helpers_1.dynamicImportOnboarding)(fetchModel, function (request) {
                    if (refFilter || findFilters || value) {
                        request.default[findModelGraphgql || graphqlQuery](_this.getFindParams(refFilter))
                            .then(function (_a) {
                            var data = _a.data;
                            if (data) {
                                var result = data[Object.keys(data)[0]];
                                if (result && typeof result == 'object') {
                                    var resultId = result[idModel || '_id' || 'id'];
                                    _this.find(resultId, result);
                                }
                                else {
                                    var firstResult = result[0];
                                    var firstResultId = result[idModel || '_id' || 'id'];
                                    _this.find(firstResultId, firstResult);
                                }
                                resolve(result);
                            }
                            _this.setState({ loading: false });
                        })
                            .catch(function (e) {
                            reject(e);
                            _this.setState({ loading: false });
                        });
                    }
                });
            });
        };
        _this.cleanData = function () {
            _this.setState({ dataList: [], foundModel: null });
        };
        _this.clearSelected = function (fields) {
            var resetFields = _this.props.form.resetFields;
            resetFields(fields);
        };
        _this.setFindFilter = function (findFilters) {
            _this.setState({ findFilters: findFilters });
        };
        _this.setDataList = function (dataList) {
            _this.setState({ dataList: dataList });
        };
        _this.onFilterData = function (value) {
            var _a;
            if (!_this.props.notAllowFetchFilter) {
                _this.setFilter((_a = {}, _a[_this.props.filterFiled] = value, _a));
                _this.list();
            }
        };
        _this.onChange = function (value) {
            var onChange = _this.props.onChange;
            onChange && onChange(value, _this.findById(value));
        };
        _this.findById = function (ids) {
            var idModel = _this.props.idModel;
            var dataList = _this.state.dataList;
            if (ids) {
                if (typeof ids === 'number' || typeof ids === 'string') {
                    return dataList.length ? dataList.find(function (item) { return item[idModel] === ids; }) : null;
                }
                return ids.map(function (id) {
                    return dataList.length ? dataList.find(function (item) { return item[idModel] === id; }) : null;
                });
            }
            return undefined;
        };
        _this.filterOption = function (inputValue, option) {
            var renderItems = _this.props.renderItems;
            var children = option.props.children;
            if (renderItems && children.key) {
                return children.key.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }
            return children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        };
        var _b = _this.props, filterFiled = _b.filterFiled, notInitialList = _b.notInitialList, filterBody = _b.filterBody;
        _this.state = {
            loading: !notInitialList,
            dataList: [],
            filters: filterBody || (_a = {
                    pageSize: 25,
                    pageNumber: 0,
                    orderBy: [
                        {
                            field: '_id',
                            order: TableProps_enum_1.SortTypes.DESC,
                        },
                    ]
                },
                _a[filterFiled || 'keyword'] = '',
                _a),
        };
        !notInitialList && _this.list();
        return _this;
    }
    RzgSelect.prototype.componentDidUpdate = function (previousProps) {
        var _a = this.props, findModelGraphgql = _a.findModelGraphgql, value = _a.value;
        var previousValue = previousProps.value;
        if (previousValue !== value && !previousValue && findModelGraphgql) {
            this.findModel();
        }
    };
    RzgSelect.prototype.getFindParams = function (refFilter) {
        var _a = this, value = _a.props.value, findFilters = _a.state.findFilters;
        if (refFilter || findFilters) {
            return __assign({}, (refFilter || findFilters));
        }
        else if (value) {
            return value;
        }
        else {
            return undefined;
        }
    };
    RzgSelect.prototype.find = function (id, data) {
        var dataList = this.state.dataList;
        if (!dataList.find(function (item) { return item._id === id; })) {
            var list = dataList;
            var foundModel = {
                _id: id,
                name: this.getName(data),
                key: id,
                data: data,
            };
            list.push(foundModel);
            this.setState({ dataList: list, foundModel: foundModel });
        }
    };
    RzgSelect.prototype.getName = function (item) {
        var titleModel = this.props.titleModel;
        if (titleModel && titleModel.includes('.')) {
            var propsArray = titleModel.split('.');
            return item[propsArray[0]][propsArray[1]];
        }
        return item[titleModel || 'name'];
    };
    RzgSelect.prototype.getNestedItems = function (list) {
        var nestedLodashQuery = this.props.nestedLodashQuery;
        if (!!nestedLodashQuery)
            return (0, lodash_1.get)(list, nestedLodashQuery, list);
        return list;
    };
    RzgSelect.prototype.isMultiple = function () {
        return this.props.mode === 'multiple';
    };
    RzgSelect.prototype.setFilter = function (filter) {
        this.setState({ filters: __assign(__assign({}, this.state.filters), filter) });
    };
    RzgSelect.prototype.renderSelectOption = function () {
        var _a = this, dataList = _a.state.dataList, renderItems = _a.props.renderItems;
        return dataList.map(function (value) { return (React.createElement(Option, { value: value._id, key: value.key }, renderItems ? React.createElement("div", { key: value.name },
            " ",
            renderItems(value),
            " ") : value.name)); });
    };
    RzgSelect.prototype.render = function () {
        var _this = this;
        var FormItem = antd_1.Form.Item;
        var _a = this.props, form = _a.form, label = _a.label, value = _a.value, propertyName = _a.propertyName, required = _a.required, placeholder = _a.placeholder, formItemLayout = _a.formItemLayout, formItemStyle = _a.formItemStyle, requiredMsg = _a.requiredMsg, disabled = _a.disabled, mode = _a.mode, defaultOption = _a.defaultOption, selectStyle = _a.selectStyle, maxTagCount = _a.maxTagCount, onSearch = _a.onSearch, defaultOptionMessage = _a.defaultOptionMessage, size = _a.size;
        var _b = this.state, loading = _b.loading, dataList = _b.dataList;
        var getFieldDecorator = form.getFieldDecorator;
        var layout = formItemLayout || {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 10 },
                lg: { span: 6 },
                xl: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
                md: { span: 12 },
                lg: { span: 15 },
                xl: { span: 17 },
            },
        };
        return (React.createElement(react_i18next_1.NamespacesConsumer, { ns: ['pages'] }, function (t) { return (React.createElement(FormItem, __assign({ hasFeedback: !_this.isMultiple(), label: label }, layout, { style: formItemStyle }), getFieldDecorator(propertyName, {
            initialValue: dataList.length > 0 ? (_this.isMultiple() ? value || undefined : value) : undefined,
            rules: [
                {
                    message: requiredMsg || (t ? t('component.Please select any') + " ".concat(label, "!") : "Please select any ".concat(label, "!")),
                    required: required,
                },
            ],
        })(React.createElement(antd_1.Select, { size: size, style: selectStyle || { width: '100%' }, className: "locationPlaceholder", mode: mode, onChange: _this.onChange, disabled: disabled || false, maxTagCount: maxTagCount || 2, placeholder: placeholder, showSearch: true, onSearch: onSearch || _this.onFilterData, loading: loading, filterOption: _this.filterOption },
            defaultOption &&
                !_this.isMultiple() && (React.createElement(Option, { value: "", key: "" }, t ? t('component.Select') : '-Select-')),
            _this.renderSelectOption())))); }));
    };
    return RzgSelect;
}(React.Component));
exports.default = RzgSelect;
